import React, { useState, useEffect } from 'react';
import styles from './Css/Navbar1.module.css';
import logo from '../assets/images/PentagonePng.png'; // Example logo
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Env from '../util/Env';
import { getAsset } from '../util/helper';

function Navbar1() {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [dropdownData, setdropdownData] = useState([]);

  useEffect(() => {
    axios.get(`${Env.server}/api/industry/AllDataFetchIndustryShapeOther`)
      .then((res) => {
        console.log('Industries:', res.data);
        setdropdownData(res.data)
      })
      .catch((err) => {
        console.log('Error fetching industries:', err);
      });

  }, [])

 
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

  const toggleDropdown = (menu) => {
    setDropdownOpen(dropdownOpen === menu ? null : menu);
  };

  const renderDropdownItems1 = (items) => (
    <>
    <div className={styles.dropdownGrid} >
      {items && items.map((item, index) => (
        <div key={index} className={styles.dropdownItem}>
          <img src={getAsset(item.image[0])} alt={item.title} className={styles.dropdownImage} />
          <a href={`/openindustry/${item._id}`} className={styles.dropdownLinkTitle}>{item.title}</a>
        </div>
      ))}
    </div>
    </>
  );

  const renderDropdownItems2 = (items) => (
    <>
    <div className={styles.dropdownGrid}>
      {items && items.map((item, index) => (
        <div key={index} className={styles.dropdownItem}>
          <img src={getAsset(item.image[0])} alt={item.title} className={styles.dropdownImage} />
          <a className={styles.dropdownLinkTitle}>{item.title}</a>
        </div>
      ))}
    </div>
    </>
  );

  const renderDropdownItems3 = (items) => (
    <>
    <div className={styles.dropdownGrid}>
      {items && items.map((item, index) => (
        <div key={index} className={styles.dropdownItem}>
          <img src={getAsset(item.image[0])} alt={item.title} className={styles.dropdownImage} />
          <a href={`/openshapes/${item._id}`} className={styles.dropdownLinkTitle}>{item.title}</a>
        </div>
      ))}
    </div>
    </>
  );

  const renderDropdownItems4 = (items) => (
    <>
    <div className={styles.dropdownGrid}>
      {items && items.map((item, index) => (
        <div key={index} className={styles.dropdownItem}>
          <img src={getAsset(item.image[0])} alt={item.title} className={styles.dropdownImage} />
          <a className={styles.dropdownLinkTitle}>{item.title}</a>
        </div>
      ))}
    </div>
    </>
  );

  return (
    <nav className={`${styles.navbar1} ${isSticky ? styles.sticky : ''}`}>
      <div className={styles.container}>
        <div className={styles.left}>
          <img src={logo} alt="Pentagone Logo" className={styles.logo} onClick={() => navigate('/')} />
          <div className={styles.desktopLinks}>
            <a href="#home" className={styles.navLink}>Home</a>

            {/* Industries Dropdown */}
            <div className={styles.dropdownContainer}
                 onMouseEnter={() => toggleDropdown('industries')}
                 onMouseLeave={() => toggleDropdown(null)}>
              <a href="/allindustries" className={styles.navLink}>Industries</a>
              {dropdownOpen === 'industries' && renderDropdownItems1(dropdownData.industries)}
            </div>

            {/* Material Dropdown */}
            <div className={styles.dropdownContainer}
                 onMouseEnter={() => toggleDropdown('material')}
                 onMouseLeave={() => toggleDropdown(null)}>
              <a href="/allmaterial" className={styles.navLink}>Material</a>
              {dropdownOpen === 'material' && renderDropdownItems2(dropdownData.material)}
            </div>

            {/* Shape & Style Dropdown */}
            <div className={styles.dropdownContainer}
                 onMouseEnter={() => toggleDropdown('shapeStyle')}
                 onMouseLeave={() => toggleDropdown(null)}>
              <Link to="/allshapestyle" className={styles.navLink}>Shape & Style</Link>
              {dropdownOpen === 'shapeStyle' && renderDropdownItems3(dropdownData.shapeStyle)}
            </div>

            {/* Label & Stickers Dropdown */}
            <div className={styles.dropdownContainer}
                 onMouseEnter={() => toggleDropdown('labelStickers')}
                 onMouseLeave={() => toggleDropdown(null)}>
              <a href="#label" className={styles.navLink}>Label & Stickers</a>
              {dropdownOpen === 'labelStickers' && renderDropdownItems4(dropdownData.labelStickers)}
            </div>
          </div>
        </div>

        <div className={styles.right}>
          <Link to="/contactus" className={styles.contactLink}>Contact Us</Link>
          <div className={styles.hamburger} onClick={toggleMobileMenu}>
            &#9776;
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className={styles.mobileMenu}>
          <a href="#home" className={styles.navLink}>Home</a>
          <a href="/allindustries" className={styles.navLink}>Industries</a>
          <a href="/allmaterial" className={styles.navLink}>Material</a>
          <a href="/allshapestyle" className={styles.navLink}>Shape & Style</a>
          <a href="/label" className={styles.navLink}>Label & Stickers</a>
          <a href="#contact" className={styles.contactLink}>Contact Us</a>
        </div>
      )}
    </nav>
  );
}

export default Navbar1;
