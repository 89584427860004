import React, { useEffect, useState } from "react";
import Styles from "./CSS/OpenIndustry.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Container from "../helper/Container";
import axios from "axios";
import Env from "../util/Env";
import { getAsset } from "../util/helper";

const fetchData = async (id) => {
    console.log("anc=====", id)
  try {
    const response = await axios.get(`${Env.server}/api/shape/getOne/${id}`);
    console.log("acb-======", response.data)
    return response.data.shapes;
  } catch (error) {
    console.error("Error fetching industry data:", error);
    throw error;
  }
};

const fetchProducts = async (industryId) => {
  try {
    const response = await axios.get(`${Env.server}/api/product/getSingleByShapeID/${industryId}`);
    return response.data.products;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

function OpenShape() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [industry, setIndustry] = useState({
    title: "Loading...",
    description: "Loading...",
    image: [],
  });
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const industryData = await fetchData(id);
        setIndustry(industryData);
      } catch (err) {
        console.error("Error in loadData:", err);
        setError("Failed to load industry data.");
      }
    };
    loadData();
  }, [id]);

  useEffect(() => {
    const loadProducts = async () => {
        console.log("load product======",industry._id)
      try {
        if (industry._id) {
          const productData = await fetchProducts(industry._id);
          setProducts(productData);
        }
      } catch (err) {
        console.error("Error in loadProducts:", err);
        setError("Failed to load products.");
      } finally {
        setLoading(false);
      }
    };
    loadProducts();
  }, [industry._id]);

  if (error) {
    return (
      <div>
        <p>An error occurred: {error}</p>
      </div>
    );
  }

  return (
    <>
      <Container>
        <div className={Styles.headingContainer}>
          <div>
            <div className={Styles.imageSection}>
              {industry.image && industry.image.length > 0 ? (
                <img
                  src={getAsset(industry.image[0])}
                  alt="Big"
                  className={Styles.bigImage}
                />
              ) : (
                <p>No Image Available</p> // Fallback for no image
              )}
            </div>
            <h1 className={Styles.heading}>{industry.title}</h1>
            <div
              className={Styles.description}
              dangerouslySetInnerHTML={{ __html: industry.description }}
            />
          </div>

          {/* Product Section */}
          <div className={Styles.productSection}>
            <h1 className={Styles.productHeading}>Our Products</h1>
            <div className={Styles.imageGrid}>
              {loading ? (
                <p>Loading Products...</p>
              ) : products?.length > 0 ? (
                products.slice(0, 10).map((product) => (
                  <div key={product._id} className={Styles.imageCard}>
                    {product.image && product.image.length > 0 ? (
                      <img
                        src={getAsset(product.image[0])}
                        alt={product.title}
                        className={Styles.image}
                      />
                    ) : (
                      <p>No Image Available</p> // Fallback for products without images
                    )}
                    <p>{product.title}</p>
                    <button className={Styles.customizeButton} onClick={() => navigate(`/openproduct/${product._id}`)}>
                      Customize ➔
                    </button>
                  </div>
                ))
              ) : (
                <p>No Products Found</p>
              )}
            </div>
            <div className={Styles.seeMoreContainer}>
              <button
                className={Styles.seeMoreButton}
                onClick={() => navigate("/allproducts")}
              >
                See More
              </button>
            </div>
          </div>
        </div>
      </Container>

      <div className={Styles.Scrollercontainer}>
        <Container>
          <div className={Styles.scrollableDescription}>
            <div
              className={Styles.description}
              dangerouslySetInnerHTML={{ __html: industry.description2 }}
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default OpenShape;
