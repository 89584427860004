import './App.css';
import ReactDOM from 'react-dom/client';
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from './helper/Navbar';
import Navbar1 from './helper/Navbar1';
import Whatsapp from './helper/Whatsapp';
import Home from './pages/Home';
import Footer from './Components/Footer'; 
import ViewAllIndustries from './pages/ViewAllIndustries';
import ViewAllProducts from './pages/ViewAllProducts';
import OpenIndustry from './pages/OpenIndustry';
import OpenProducts from './pages/OpenProducts';
import ViewAllMaterials from './pages/ViewAllMaterials';
import ViewAllShapeStyle from './pages/VieAllShapeStyle'; 
import OpenShape from './pages/OpenShape';
import Chatbot from './util/Chatbot';

export default function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Navbar1 />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/allindustries" element={<ViewAllIndustries />} />
        <Route path="/allmaterial" element={<ViewAllMaterials />} />
        <Route path="/allshapestyle" element={<ViewAllShapeStyle />} />
        <Route path="/allproducts" element={<ViewAllProducts />} />

        <Route path="/openproduct/:id" element={<OpenProducts />} />
        <Route path="/openshapes/:id" element={<OpenShape/>} />
        <Route path="/openindustry/:id" element={<OpenIndustry />} />
        {/* <Route path="/openindustry" element={<OpenProducts />} /> */}
        
      </Routes>
      <Whatsapp />
      <Footer />
      <Chatbot/>      
    </BrowserRouter>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
