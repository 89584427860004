import React, { useState } from 'react';
import styles from './Css/QuotForm.module.css';
import Env from '../util/Env';
import { useDropzone } from "react-dropzone";
import axios from 'axios';


function QuoteForm() {
  // Define state for each form field
  const [productInfo, setProductInfo] = useState('');
  const [imageUpload, setImageUpload] = useState(null); // for image file
  const [field1, setField1] = useState('');
  const [field2, setField2] = useState('');
  const [selectField, setSelectField] = useState('');
  const [selectField2, setSelectField2] = useState('');
  const [selectField3, setSelectField3] = useState('');
  const [field3, setField3] = useState('');
  const [field4, setField4] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [image, setImages] = useState([]);
  const [imagesFront, setImagesFront] = useState([]);
  const [loading, setLoading] = useState(false);

  const colorOptions = [
    { value: "red", label: "Red" },
    { value: "blue", label: "Blue" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "orange", label: "Orange" },
    { value: "purple", label: "Purple" },
    { value: "black", label: "Black" },
    { value: "white", label: "White" },
    { value: "pink", label: "Pink" },
    { value: "brown", label: "Brown" },
    { value: "gray", label: "Gray" },
    { value: "violet", label: "Violet" },
    { value: "magenta", label: "Magenta" },
    { value: "teal", label: "Teal" },
    { value: "cyan", label: "Cyan" },
    { value: "maroon", label: "Maroon" },
    { value: "olive", label: "Olive" },
    { value: "navy", label: "Navy" },
    { value: "lime", label: "Lime" },
    { value: "coral", label: "Coral" },
    { value: "salmon", label: "Salmon" },
    { value: "indigo", label: "Indigo" },
    { value: "turquoise", label: "Turquoise" },
    { value: "gold", label: "Gold" },
    { value: "silver", label: "Silver" },
    { value: "beige", label: "Beige" },
    { value: "peach", label: "Peach" },
    { value: "lavender", label: "Lavender" },
    { value: "khaki", label: "Khaki" },
    { value: "orchid", label: "Orchid" },
    { value: "plum", label: "Plum" },
    { value: "crimson", label: "Crimson" },
    { value: "azure", label: "Azure" },
    { value: "charcoal", label: "Charcoal" },
    { value: "amber", label: "Amber" },
    { value: "amethyst", label: "Amethyst" },
    { value: "apricot", label: "Apricot" },
    { value: "aqua", label: "Aqua" },
    { value: "aquamarine", label: "Aquamarine" },
    { value: "babyBlue", label: "Baby Blue" },
    { value: "banana", label: "Banana" },
    { value: "blackberry", label: "Blackberry" },
    { value: "blush", label: "Blush" },
    { value: "bronze", label: "Bronze" },
    { value: "burgundy", label: "Burgundy" },
    { value: "cherry", label: "Cherry" },
    { value: "chocolate", label: "Chocolate" },
    { value: "copper", label: "Copper" },
    { value: "cream", label: "Cream" },
    { value: "denim", label: "Denim" },
    { value: "emerald", label: "Emerald" },
    { value: "fuchsia", label: "Fuchsia" },
    { value: "jade", label: "Jade" },
    { value: "lavenderBlush", label: "Lavender Blush" },
    { value: "lemon", label: "Lemon" },
    { value: "lightCoral", label: "Light Coral" },
    { value: "lightCyan", label: "Light Cyan" },
    { value: "lightGoldenRodYellow", label: "Light Golden Rod Yellow" },
    { value: "lightGreen", label: "Light Green" },
    { value: "lightPink", label: "Light Pink" },
    { value: "lightSalmon", label: "Light Salmon" },
    { value: "lightSeaGreen", label: "Light Sea Green" },
    { value: "lightSkyBlue", label: "Light Sky Blue" },
    { value: "lightSlateGray", label: "Light Slate Gray" },
    { value: "lightSteelBlue", label: "Light Steel Blue" },
    { value: "lightYellow", label: "Light Yellow" },
    { value: "midnightBlue", label: "Midnight Blue" },
    { value: "mintCream", label: "Mint Cream" },
    { value: "mistyRose", label: "Misty Rose" },
    { value: "moccasin", label: "Moccasin" },
    { value: "navajoWhite", label: "Navajo White" },
    { value: "oldLace", label: "Old Lace" },
    { value: "paleGreen", label: "Pale Green" },
    { value: "paleTurquoise", label: "Pale Turquoise" },
    { value: "papayaWhip", label: "Papaya Whip" },
    { value: "peru", label: "Peru" },
    { value: "pinkPearl", label: "Pink Pearl" },
    { value: "powderBlue", label: "Powder Blue" },
    { value: "rosyBrown", label: "Rosy Brown" },
    { value: "royalBlue", label: "Royal Blue" },
    { value: "sandyBrown", label: "Sandy Brown" },
    { value: "seaShell", label: "Sea Shell" },
    { value: "sienna", label: "Sienna" },
    { value: "skyBlue", label: "Sky Blue" },
    { value: "slateBlue", label: "Slate Blue" },
    { value: "slateGray", label: "Slate Gray" },
    { value: "snow", label: "Snow" },
    { value: "springGreen", label: "Spring Green" },
    { value: "steelBlue", label: "Steel Blue" },
    { value: "tan", label: "Tan" },
    { value: "thistle", label: "Thistle" },
    { value: "tomato", label: "Tomato" },
    { value: "wheat", label: "Wheat" },
    { value: "whiteSmoke", label: "White Smoke" },
    { value: "zinnwaldite", label: "Zinnwaldite" },
    { value: "aliceBlue", label: "Alice Blue" },
    { value: "antiqueWhite", label: "Antique White" },
    { value: "bisque", label: "Bisque" },
    { value: "blueViolet", label: "Blue Violet" },
    { value: "cadetBlue", label: "Cadet Blue" },
    { value: "chartreuse", label: "Chartreuse" },
    { value: "cornflowerBlue", label: "Cornflower Blue" },
    { value: "darkCyan", label: "Dark Cyan" },
    { value: "darkGoldenRod", label: "Dark Golden Rod" },
    { value: "darkGray", label: "Dark Gray" },
    { value: "darkKhaki", label: "Dark Khaki" },
    { value: "darkMagenta", label: "Dark Magenta" },
    { value: "darkOliveGreen", label: "Dark Olive Green" },
    { value: "darkOrange", label: "Dark Orange" },
    { value: "darkOrchid", label: "Dark Orchid" },
    { value: "darkRed", label: "Dark Red" },
    { value: "darkSalmon", label: "Dark Salmon" },
    { value: "darkSeaGreen", label: "Dark Sea Green" },
    { value: "darkSlateBlue", label: "Dark Slate Blue" },
    { value: "darkSlateGray", label: "Dark Slate Gray" },
    { value: "darkTurquoise", label: "Dark Turquoise" },
    { value: "deepPink", label: "Deep Pink" },
    { value: "dodgerBlue", label: "Dodger Blue" },
    { value: "fireBrick", label: "Fire Brick" },
    { value: "floralWhite", label: "Floral White" },
    { value: "forestGreen", label: "Forest Green" },
    { value: "gainsboro", label: "Gainsboro" },
    { value: "ghostWhite", label: "Ghost White" },
    { value: "goldenRod", label: "Golden Rod" },
    { value: "honeyDew", label: "Honey Dew" },
    { value: "hotPink", label: "Hot Pink" },
    { value: "indianRed", label: "Indian Red" },
    { value: "ivory", label: "Ivory" },
    { value: "lavenderGrey", label: "Lavender Grey" },
    { value: "lightSlateBlue", label: "Light Slate Blue" },
    { value: "mediumOrchid", label: "Medium Orchid" },
    { value: "mediumSeaGreen", label: "Medium Sea Green" },
    { value: "mediumSlateBlue", label: "Medium Slate Blue" },
    { value: "mediumSpringGreen", label: "Medium Spring Green" },
    { value: "midnightRose", label: "Midnight Rose" },
    { value: "neonGreen", label: "Neon Green" },
    { value: "oliveDrab", label: "Olive Drab" },
    { value: "paleVioletRed", label: "Pale Violet Red" },
    { value: "pearlWhite", label: "Pearl White" },
    { value: "ruby", label: "Ruby" },
    { value: "scarlet", label: "Scarlet" },
    { value: "sapphire", label: "Sapphire" },
    { value: "sepia", label: "Sepia" },
    { value: "sunflower", label: "Sunflower" },
    { value: "ultramarine", label: "Ultramarine" },
    { value: "vermillion", label: "Vermillion" },
    { value: "vividGreen", label: "Vivid Green" },
    { value: "wine", label: "Wine" },
    { value: "zircon", label: "Zircon" }
  ];
  
  const ClearIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      style={{ fill: 'currentColor' }}
    >
      <path d="M19 6h-2.414l-1.293-1.293a1 1 0 0 0-.707-.293H8.414a1 1 0 0 0-.707.293L6.414 6H4a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V8a2 2 0 0 0-2-2zM12 12l4 4-1.414 1.414L12 13.414l-4.586 4.586L6 16l4-4-4-4 1.414-1.414L12 10.586l4.586-4.586L18 8l-4 4z" />
    </svg>
  );

  const [fileName, setFileName] = useState('');
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      setFileName(file.name); // Update the file name state
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    
    // Console log the data according to the schema names
    const formData = {
      productInfo,    // maps to productInfo in schema
      images: imageUpload, // maps to images in schema
      width: field1,   // Assuming "width" for field1
      length: field2,  // Assuming "length" for field2
      units: selectField, // maps to units in schema
      color: selectField2, // Assuming "type" for selectField2
      stock: selectField3, // maps to stock in schema
      height:field4,
      depth: field3,   // Assuming "depth" for field3
      name,            // maps to name in schema
      email,           // maps to email in schema
      phNum: number,   // maps to phNum in schema
      message: additionalInfo // maps to message in schema
    };

    console.log('Form Data:', formData);

    axios.post(`${Env.server}/api/quoteform/PostQuoteInformation`, formData)
      .then((response) => {
        console.log('Form submitted successfully:', response.data);
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });

    // You can now send this formData to the backend or process it as needed.
  };



  // image upload
  const handleInsideClick = (e) => {
    e.stopPropagation();
    const isClickable =
      e.target.tagName === "DIV" || e.target.tagName === "INPUT"; // Check if the clicked element is the drop zone or input
    if (isClickable) {
      if (e.target.tagName === "DIV") {
        document.getElementById("fileInput").click(); // Trigger the file input click event
      }
    }
  };

  const onDrop = (acceptedFiles) => {
    setImages(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setImagesFront(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const removeImage = (index) => {
    const updatedImagesfront = [...imagesFront];
    updatedImagesfront.splice(index, 1);
    setImagesFront(updatedImagesfront);

    const updatedImages = [...image];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const publishImage = () => {
    console.log("abc===",image);
    if (imagesFront.length > 0) {
      console.log("imagesFront", imagesFront);
      console.log("image", image);
      const formData = new FormData();
      formData.append("imagefront", imagesFront);

      image && image.map((img) => {
          formData.append(`images`, img);
        });

      console.log("formData", formData);

      console.log("image", formData);
      let objectNames = [];
      axios
        .post(`${Env.server}/api/upload/multiple`, formData)
        .then((res) => {
          let resp = res.data.file;
          for (let i = 0; i < resp.length; i++) {
            objectNames.push("/images/" + resp[i].filename);
          }
        //   setResponseImages(objectNames);
          if (res.data.message === "imagesSaved") {
              console.log("image", objectNames)
            publishBlogNow(objectNames);
          } else {
            alert("error not in catch");
          }
        })
        .catch((err) => {
          alert("catch error");
        });
    } else {
      alert("Please select image");
    }
  };

  const publishBlogNow = (objectNames) => {
    console.log("data-----", objectNames)
    setLoading(true);

    const formData = {
      productInfo,
      images: objectNames,
      width: field1,
      length: field2,
      units: selectField,
      color: selectField2,
      stock: selectField3,
      height:field4,
      depth: field3,
      name,
      email,
      phNum: number,
      message: additionalInfo
    };

    axios
      .post(`${Env.server}/api/quoteform/PostQuoteInformation`, formData)
      .then((response) => {
        console.log("response", response.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
    }

  return (
    <>
     {loading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}

    <div className={styles.formContainer}>
      <h2>Get Quote</h2> 
        {/* Product Information */}
        <div className={styles.formField}>
          <label>Product Information</label>
          <input 
            type="text" 
            name="productInfo" 
            className={styles.inputField} 
            placeholder="Enter product details" 
            value={productInfo}
            onChange={(e) => setProductInfo(e.target.value)}
          />
        </div>

        {/* Image Uploader */}
        <div className="form-group text-center">
                              <div
                                onClick={handleInsideClick}
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  width: "140px",
                                  height: "50px",
                                  border: "1px dashed #aaa",
                                  cursor: "pointer",
                                  position: "relative",
                                  borderRadius: "5px",
                                  overflow: "hidden",
                                  backgroundColor: "black",
                                }}
                              >
                                <h3
                                  style={{ color: "white", marginTop: "12px", marginLeft:"5px" }}
                                >
                                  Choose Image
                                </h3>
                                <input
                                  {...getInputProps()}
                                  id="fileInput" // Ensure this ID is present
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    opacity: 0,
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                              {imagesFront.length > 0 && (
                                  <div
                                  >
                                    <p style={{fontSize:"10px"}}>
                                    {/* for one or more image */}
                                      {imagesFront[0].name}
                                    </p>
                                  </div>
                                )

                              }
                            </div>





        {/* 3 Input Fields and 1 Select Field */}
        <div className={`${styles.formField} ${styles.row}`}>
          <input 
            type="text" 
            name="field1" 
            className={styles.inputField} 
            placeholder="Width" 
            value={field1}
            onChange={(e) => setField1(e.target.value)}
          />
          <input 
            type="text" 
            name="field2" 
            className={styles.inputField} 
            placeholder="Length" 
            value={field2}
            onChange={(e) => setField2(e.target.value)}
          />

<input 
            type="text" 
            name="field2" 
            className={styles.inputField} 
            placeholder="Height" 
            value={field4}
            onChange={(e) => setField4(e.target.value)}
          />

          <select 
            name="selectField" 
            className={styles.selectField}
            value={selectField}
            onChange={(e) => setSelectField(e.target.value)}
          >
            <option value="" disabled>Select Units</option>
            <option value="inch">inch</option>
            <option value="cm">cm</option>
            <option value="mm">mm</option>
          </select>
        </div>

        {/* 2 Select Fields and 1 Input Field */}
        <div className={`${styles.formField} ${styles.row}`}>
          <select 
            name="selectField2" 
            className={styles.selectField}
            value={selectField2}
            onChange={(e) => setSelectField2(e.target.value)}
          >
            <option value="" disabled>Color</option>
            {colorOptions.map((color, index) => (
      <option key={index} value={color.value}>
        {color.label}
      </option>
    ))}
          </select>
          <input 
  type="number" 
  name="selectField3" 
  className={styles.selectField}
  value={selectField3}
  onChange={(e) => setSelectField3(e.target.value)}
  placeholder="Enter Stock Quantity"
/>

          <input 
            type="text" 
            name="field3" 
            className={styles.inputField} 
            placeholder="Depth" 
            value={field3}
            onChange={(e) => setField3(e.target.value)}
          />
        </div>

        {/* Name, Email, Number */}
        <div className={`${styles.formField} ${styles.row}`}>
          <input 
            type="text" 
            name="name" 
            className={styles.inputField} 
            placeholder="Name" 
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input 
            type="email" 
            name="email" 
            className={styles.inputField} 
            placeholder="Email" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input 
            type="tel" 
            name="number" 
            className={styles.inputField} 
            placeholder="Phone Number" 
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
        </div>

        {/* Textbox */}
        <div className={styles.formField}>
          <label>Additional Information</label>
          <textarea 
            name="additionalInfo" 
            className={styles.textareaField} 
            placeholder="Enter any additional information"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
          ></textarea>
        </div>

        {/* Submit Button */}
        <button type="submit" onClick={publishImage} className={styles.submitButton}>
          Submit
        </button>

    </div>
    </>
  );
}

export default QuoteForm;
