import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './CSS/ViewAllIndustries.module.css';
import Container from '../helper/Container';
import Env from '../util/Env';
import { getAsset } from '../util/helper';
import { useNavigate } from 'react-router-dom';


function ViewAllIndustries() {
const navigate = useNavigate();
  const [allIndustries, setAllIndustries] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state

  // Fetch industries data from API on component mount
  useEffect(() => {
    GetAllIndustries();
  }, []);

  // API call to get all industries
  const GetAllIndustries = () => {
    axios
      .get(`${Env.server}/api/industry/getAll`) // Replace with your actual environment variable or server URL
      .then((res) => {
        setAllIndustries(res.data.industries); // Assuming `industries` is the correct field in the API response
        setLoading(false); // Data fetched, set loading to false
      })
      .catch((err) => {
        console.log('Error fetching industries:', err);
        setLoading(false); // Error occurred, set loading to false
      });
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <Container>
          <h1 className={styles.mainHeading}>Blockbuster Custom Printed Boxes</h1>
          <h2 className={styles.subHeading}>Go Well For Every Industry</h2>
          <p className={styles.description}>
            We operate on the principle of prioritizing customers over industries. You are special to us, so whatever products you are promoting, we enhance their beauty with meticulously crafted boxes. Industry-wide design gallery or product display is a way to assist you in finding your right corner. Choose by industry and make the perfect choice you deserve.
          </p>
        </Container>
      </div>

      {/* Image Section */}
      <div className={styles.imageSection}>
        <Container>
          <h2 className={styles.categoryHeading}>Customize Boxes</h2>
          <div className={styles.imageGrid}>
            {loading ? (
              <p>Loading industries...</p>
            ) : allIndustries.length > 0 ? (
              allIndustries.map((industry) => (
                <div key={industry.id} className={styles.imageCard}>
                  <img
                    src={getAsset(industry.image[0])}
                    alt={industry.title} // Adjust based on your API response field names
                    className={styles.image}
                  />
                  <p>{industry.title}</p>
                  <button className={styles.customizeButton} onClick={() => navigate(`/openindustry/${industry._id}`)}>Customize ➔</button>
                </div>
              ))
            ) : (
              <p>No industries available</p>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}

export default ViewAllIndustries;
