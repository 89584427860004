import React,{useState,useEffect} from 'react';
import axios from 'axios';
import Env from '../util/Env';
import { getAsset } from '../util/helper';
import Container from '../helper/Container';
import styles from './Css/Product.module.css';
import { useNavigate } from 'react-router-dom';


function Product() {
  const navigate = useNavigate();

    const [GetProduct, setGetProd] = useState([]);
    
    useEffect(() => {
        getBlogs();
    },[])

    const getBlogs = () => {
        axios.get(`${Env.server}/api/product/getAllProduct`)
            .then((resp) => {
                let res = resp.data.products;
                setGetProd(res);
            })
            .catch((err) => {
                console.log("err", err);
            })
    }

  return (
<div style={{paddingBottom:"40px"}}>
  <Container>
    <div className={styles.header}>
      <h1 className={styles.titleMain}>Product:</h1>
      <button className={styles.viewAllButton} onClick={() => navigate("/allproducts")}>View All</button>
    </div>
    <div className={styles.carousel}>
      <div className={styles.innerCarousel}>
        {GetProduct.map((cart) => (
          <div key={cart.id} className={styles.cart}>
            <img src={getAsset(cart.image[0])} alt={cart.title} className={styles.image} />
            <h3 className={styles.title}>{cart.title}</h3>
            <button className={styles.button} onClick={() => navigate(`/openproduct/${cart._id}`)}>Get a Quote</button>
          </div>
        ))}
      </div>
    </div>
  </Container>
</div>

  );
}

export default Product;
